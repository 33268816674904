@font-face {
  font-family: "Open Sans";
  src: url("../public/fonts/OpenSans-Regular.ttf");
}

@font-face {
  font-family: "Roboto";
  src: url("../public/fonts/Roboto/Roboto-Medium.ttf");
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url("../public/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf");
}

@font-face {
  font-family: "Poppins";
  src: url("../public/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Prompt";
  src: url("../public/fonts/Prompt/Prompt-Regular.ttf");
}

* {
  font-family: "Poppins", "Prompt" !important;
}

.login-container {
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgb(126 207 233) 0%, rgb(35 101 171) 100%);
}

.logo-login svg {
  position: absolute;
  top: -2%;
  left: 50%;
  color: white;
  background: #2e7dad;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 5px solid white;
  padding: 10px;
}

.card-li {
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.card-li:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  opacity: 0.8;
}

.card-main {
  border: 1px solid #FCFCFC;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 32px 24px 64px 24px;
}

.site-layout {
  margin-left: 250px;
}

.img-logo {
  width: 100px;
  height: 100px;
}

.header-ui .mobild-ui {
  display: none;
}

.table-list>.ant-card-body {
  padding: 0 !important;
}

.card-drop-here {
  width: 100%;
  height: 190px;
  cursor: pointer;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 8px;
  text-align: center;
}

.card-drop-here:hover,
.card-drop-here:active {
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.card-drop-here.disabled {
  opacity: 0.6;
}


.ant-modal-confirm-error .ant-modal-confirm-body {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.ant-modal-confirm-error .ant-modal-confirm-body .anticon {
  margin-left: auto;
  margin-right: auto;
  font-size: 75px;
}

.ant-modal-confirm-error .ant-modal-confirm-body .ant-modal-confirm-paragraph {
  padding-top: 20px;
  max-width: 100% !important;
}

.ant-modal-confirm-paragraph {
  max-width: 100% !important;
}

.ant-modal-confirm-error .ant-modal-confirm-btns {
  text-align: center;
}


.ant-modal-confirm-success .ant-modal-confirm-body {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.ant-modal-confirm-success .ant-modal-confirm-body .anticon {
  margin-left: auto;
  margin-right: auto;
  font-size: 75px;
  color: #63A8C3;
}

.ant-modal-confirm-success .ant-modal-confirm-body .ant-modal-confirm-paragraph {
  padding-top: 20px;
}

.ant-modal-confirm-success .ant-modal-confirm-btns {
  text-align: center;
}

.ant-modal-confirm-warning .ant-modal-confirm-body,
.ant-modal-confirm-confirm .ant-modal-confirm-body {
  display: block;
  text-align: center;
  padding-top: 20px;
}

.ant-modal-confirm-warning .ant-modal-confirm-body .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body .anticon {
  margin-left: auto;
  margin-right: auto;
  font-size: 75px;
}

.ant-modal-confirm-warning .ant-modal-confirm-body .ant-modal-confirm-paragraph,
.ant-modal-confirm-confirm .ant-modal-confirm-body .ant-modal-confirm-paragraph {
  padding-top: 20px;
}

.ant-modal-confirm-warning .ant-modal-confirm-btns,
.ant-modal-confirm-confirm .ant-modal-confirm-btns {
  text-align: center;
}

.ant-modal-confirm-success .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #63A8C3 !important;
  border-radius: 20px;
  height: 40px;
  min-width: 60px;
}

.ant-modal-confirm-error .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #000 !important;
  border-radius: 20px;
  height: 40px;
  min-width: 60px;
}

.ant-modal-confirm-warning .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #000 !important;
  border-radius: 20px;
  height: 40px;
  min-width: 60px;
}

.ant-modal-confirm-title {
  font-size: 18px !important;
}

.ant-modal-confirm-content {
  font-size: 16px !important;
}

.ant-modal-confirm-confirm .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-primary {
  margin: 0;
  background-color: #000;
}

.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-primary:hover,
.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-primary:active {
  background-color: #000;
}

.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-default {
  background-color: #000;
  color: #fff;
  border-color: #000;

}

.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-default:hover,
.ant-modal-confirm-confirm .ant-modal-confirm-btns .ant-btn-default:active {
  background-color: #000;
  color: #fff;
  border-color: #000;
}

.ant-btn-danger {
  background-color: #FF0000 !important;
  /* border-radius: 20px; */
  /* height: 40px; */
  min-width: 60px;
  color: #fff;
}

.main-container {
  display: flex;
}


.public-form {
  padding: 64px 8px;
  margin: 0px auto;
  width: 50%;
  text-align: start;
}

.logo-container {
  text-align: center;
  margin-bottom: 25px;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.list-container {
  display: flex;
  width: 100%;
  /* background-color:#ededed; */
}

.card-container {
  width: 100%;
  padding: 0px 0px;
}

.card {
  background-color: white;
  padding: 10px 20px;
  margin: 10px 0;
  position: relative;
  transition: all 0.2s;
}

.card::after {
  content: "";
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 20px;
}

.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.serial-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  padding-right: 10px;
  margin-top: -5px;
  min-width: 110px;
}

.serial-container span:nth-child(1) {
  font-size: 10px;
  font-weight: 500;
}

.serial-container span:nth-child(2) {
  font-weight: bold;
}

.divider {
  width: 1.5px;
  background: rgba(5, 5, 28, 0.22);
  height: 45px;
}

.status-container {
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
}

.card.planned {
  background: white;
}

.card.planned::after,
.card.planned .circle {
  background-color: #B9B9B9;
}

.card.in-progress {
  background: #FFF1925E;
}

.card.in-progress::after,
.card.in-progress .circle {
  background: #FFDD3C;
}

.card.contact-helpdesk {
  background: #F5DCE0;
}

.card.contact-helpdesk::after,
.card.contact-helpdesk .circle {
  background: #FF0000;
}

.card.checklist-done {
  background: #E5EFF6;
}

.card.checklist-done::after,
.card.checklist-done .circle {
  background: #116BEA;
}

.card.installation-completed {
  background: #e6f6e5;
}

.card.installation-completed::after,
.card.installation-completed .circle {
  background: #8fd14f;
}

.detail-container {
  display: flex;
  width: 100%;
}

.planned-container {
  line-height: 2em;
}

.bold {
  font-weight: bold;
}

.indent {
  margin-left: 30px;
}

.product-name {
  font-size: 24px;
  font-weight: 700;
  margin: 5px 0;
}

.product-detail {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
}

.nn-steps-current {
  text-align: left;
  color: var(--primary-orange, #F28626);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.nn-steps-view {
  padding-top: 4px;
  padding-bottom: 8px;
}

.nn-space-between-xl {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.ant-steps-item-finish .ant-steps-item-title::after {
  background-color: #F28626 !important;
  height: 2px !important;
}

.ant-steps-item-icon {
  background: #fff !important;
  border: 1px solid #C8C8CD !important;
}

.ant-steps-icon {
  font-weight: 600;
  color: #C8C8CD !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: #F28626 !important;
  border: 1px solid #F28626 !important;
}

.ant-steps-item-finish .ant-steps-icon {
  font-weight: 600;
  color: #fff !important;
}


.ant-steps-item-active .ant-steps-item-icon {
  background: #fff !important;
  border: 1px solid #F28626 !important;
}

.ant-steps-item-active .ant-steps-icon {
  font-weight: 600;
  color: #F28626 !important;
}

.ant-form-item {
  margin: 0;
}

.ant-form-item-row {
  align-items: flex-start;
}

.ant-form-item-label {
  margin-top: .75rem;
}

.ant-form-item-label-left {
  min-width: 200px !important;
}

.ant-form-item-label>label {
  font-size: 16px !important;
}

.step-container {
  margin: 20px 0;
  padding: 0px 8px;
}

.next-container {
  width: 50%;
  margin: 20px auto;
}

.next-container button {
  background: #3D3D3D;
}

.main-comment {
  border: 1px solid #ced4da;
  border-radius: 6px;
  padding: 8px;
}

.header-comment {
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
}

.btn-group-stop {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}

.ant-menu-item {
  padding: 0 10px 0 15px !important;
  width: 100% !important;
  margin-inline: 0 !important;
}

.ant-menu-item-selected {
  background: #000 !important;
  border-radius: 0 !important;
}


.ant-menu-item-selected:before {
  content: "";
  position: absolute;
  background: #008DC2;
  height: 100%;
  width: 7px;
  left: 0;
}

.ant-menu-title-content {
  margin-inline-start: 10px !important;
}

.ant-radio-wrapper {
  gap: .5rem;
}

.spin-loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #00000030;
  z-index: 10000;
  inset: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.card-list {
  text-align: left;
}

.card-list.doc:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background: #6abbdb !important;
}

.card-list.doc.collapse .ant-card-head {
  padding: 0;
}

.card-list.doc.collapse .ant-card-body {
  padding: 0;
}

.card-list.doc.collapse .ant-card-body .ant-collapse-header {
  display: none !important;
}

.card-list.doc.collapse .ant-card-body .ant-collapse-content-box {
  padding: 0 !important;
}


.ant-spin-dot-item {
  background-color: #fff !important;
}

.phone-box {
  display: flex;
  gap: 5px;
}

.phone-box .ant-select-selector {
  padding: 0 0 0 15px !important;
}

.phone-box .ant-form-item:nth-child(1) {
  width: 170px;
}

.phone-box .ant-form-item:nth-child(2) {
  width: 100%;
}

.ant-form-item-required::before {
  display: none !important;
}

.megaforce-form-list .ant-form-item-control-input-content {
  max-width: 300px;
}

.ant-select {
  width: 95%;
  height: 95%;
}

.scdropdown .ant-select-selector {
  border: none !important;
  box-shadow: unset !important;
  padding: 0 10px !important;
}

.search-tds {
  width: 300px !important;
}

.search-tds .ant-select-arrow {
  inset-inline-end: 15px !important;
}

.search-tds .ant-select-selector {
  padding: 0 12px !important;
}

.ant-select-item-option-content {
  width: 100%;
}

.status-list.active {
  color: #1890FF !important;
  background: white !important;
}

.draft-list-control .btn-control svg:hover {
  color: #1890FF;
}

.no-data {
  text-align: center;
}

.list-done-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  gap: 20px
}

.list-done-detail-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1
}

.list-done-detail {
  display: flex;
  gap: 20px;
  align-items: center;
}

.done-table {
  width: 70%;
}

#login .ant-form-item-required::before {
  display: none !important;
}

.account-login {
  color: white;
  text-align: right;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.ant-layout-header {
  height: 75px;
  line-height: 75px;
}

.account-login .account-login-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 100%;
  color: #646965;
}

.account-logout {
  display: flex;
  gap: 10px;
}

.account-login .account-logo {
  background: #BFBFBF;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  margin-left: 10px;
  cursor: pointer;
}

.account-login .account-logo span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ant-dropdown-menu {
  width: 100px;
}

.list-limit .ant-select-selection-item {
  padding-inline-end: 0 !important;
}

.list-limit-option {
  text-align: center;
}

.search-clear {
  display: flex;
  gap: 20px;
  flex-grow: 1;
  justify-content: end;
}

.header-insert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.tooltip-icon .ant-tooltip-inner {
  padding: 10px !important;
}

.ant-pagination-simple-pager input {
  font-size: 16px;
}

.generate-set {
  display: flex;
  width: 250px;
  justify-content: center;
  border: 2px solid #63A8C3;
  padding: 35px 0px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  color: #038CC0;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.generate-set span {
  padding: 0 10px;
}

.generate-set:hover {
  border-color: #038CC0;
  color: #038CC0;
  background-color: #7fb3c728;
}

.header-container {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 26px;
}

.subheader-container {
  color: #737C89;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
}

#reader video {
  width: 100% !important;
}

.ant-table-cell {
  padding: .5rem !important;
}

.pdf-preview-container {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  position: relative;
}

.rma-detail-container {
  display: flex;
  width: 100%;
  gap: 30px;
}

.img-zoom {
  object-fit: contain;
  border: 1px dashed #d9d9d9;
  cursor: zoom-in;
}

.img-zoom:hover {
  opacity: 0.8;
}

.ant-image-preview-operations {
  flex-wrap: wrap;
}

.ant-image-preview-operations-operation {
  margin-inline-start: 0 !important;
}

.search-clear-rma {
  display: flex;
  align-items: end;
  gap: 20px;
  margin: 0 auto;
  flex: 1;
}

.search-clear-rma button {
  width: 100% !important;
}

.ant-row.ant-form-item-row {
  gap: 20px;
}

.ant-form-item .ant-form-item-label>label {
  text-wrap: wrap;
  height: 100%;
}

.ant-form-item-label>label::after {
  content: "" !important;
}

.blank .ant-form-item-control {
  display: none;
}

.ant-upload-wrapper {
  display: inline-block !important;
}

.ant-upload-list {
  display: none !important;
}

.doc-set {
  margin: 0 10px 20px;
}

.doc-set h4 {
  color: #646965;
}

.doc-upload {
  display: flex;
  width: 100%;
  padding: 15px;
}

.doc-upload .ant-form-item-label {
  text-align: left !important;
}

.ant-upload .ant-btn,
.plus-btn,
.minus-btn {
  background: #63A8C3 !important;
  color: #fff !important;
  border-color: #63A8C3 !important;
  border-radius: 50%;
}

.minus-btn {
  background: #e73030 !important;
  border-color: #e73030 !important;
}

.ant-upload .ant-btn:hover,
.plus-btn:hover {
  background: #6abbdb !important;
  border-color: #6abbdb !important;
}

.minus-btn:hover {
  background: #FF0000 !important;
  border-color: #FF0000 !important;
}

.input-upload {
  max-width: 600px;
  width: calc(100% - 200px);
  min-width: 150px;
  margin: 0 20px 20px 0;
}

.header-action {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.blue-btn {
  background: #63A8C3;
  color: #fff;
  height: 40px;
  width: 100px;
  border-color: #63A8C3;
}

.blue-btn:disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: #ABB3BE;
  background: #F3F5F8;
  box-shadow: none;
}

.cancel-btn {
  background: #fff;
  color: #63A8C3;
  height: 40px;
  width: 100px;
  border-color: #d9d9d9;
}

.blue-btn:not(:disabled):hover {
  background: #6abbdb !important;
  border-color: #6abbdb !important;
  color: #fff !important;
}

.cancel-btn:hover {
  border-color: #63A8C3 !important;
  color: #63A8C3 !important;
}

.back-btn {
  background: none !important;
  border-color: none !important;
  color: #63A8C3 !important;
  border: none !important;
}

.back-btn:hover {
  text-decoration: underline !important;
  background: none !important;
  border-color: none !important;
  color: #63A8C3 !important;
  border: none !important;
}

.icon-action {
  color: #1f9acb;
  cursor: pointer;
  font-size: 20px;
}

.icon-action:hover {
  color: #1a7295;
}

.custom-form-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.custom-label {
  text-align: right;
  min-width: 150px;
  margin-right: 8px;
}

.modal-label-container {
  display: flex;
  margin-bottom: 15px;
  gap: 10px;
}

.modal-label {
  flex-basis: 200px;
  min-width: 200px;
}

.bold-first-line {
  font-weight: normal;
}

.bold-first-line::first-line {
  font-weight: bold;
}

.save-doc-btn,
.print-doc-btn {
  padding: 10px 50px;
  background: #4CAF50;
  color: white;
  font-size: 25px;
  border-color: #3fac43;
}

.print-doc-btn {
  background: #63A8C3;
  border-color: #63A8C3;
}

.save-doc-btn:hover {
  background: #2ea732 !important;
  color: white !important;
  border-color: #2ea732 !important;
}

.print-doc-btn:hover {
  background: #6abbdb !important;
  border-color: #6abbdb !important;
  color: white !important;
}

.star .ant-form-item-label {
  overflow: visible !important;
}

.star label::after {
  content: "*" !important;
  position: absolute !important;
  top: 0 !important;
  right: -15px !important;
  color: red !important;
  visibility: visible !important;
}

.require-red {
  border-color: red !important;
  box-shadow: none !important;
}

.anticon.anticon-exclamation-circle {
  color: #6abbdb !important;
}

.login-btn {
  margin: 20px 0;
  padding: 0 0 0 50px;
  background: linear-gradient(to right, #1f6793, #2786b3, #1f6793);
  border-radius: 30px;
  box-shadow: none !important;
}

.login-btn:hover {
  background: linear-gradient(to right, #2980b9, #3498db, #2980b9) !important;
}

.ant-table-expanded-row-fixed {
  width: 100% !important;
}

.ant-table-expanded-row-fixed:after {
  content: unset !important;
}

.ant-collapse {
  border-radius: 0 !important;
}

.ant-collapse-header {
  padding: 15px !important;
}

.custom-textarea {
  max-width: 97% !important;
  height: 100px !important;
}

.textarea-count {
  color: #a9a9a9;
  position: absolute;
  right: 3%;
  bottom: -10px;
}

.setting-other-container {
  margin: 0 50px 30px;
  display: flex;
  gap: 10px;
  align-items: flex-end;
  flex-wrap: wrap;
}

.setting-other-container>span {
  width: 200px;
  word-break: break-word;
}

.setting-other {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* max-width: 200px; */
}

.ant-drawer {
  display: none !important;
}

.logout-mobile {
  color: rgba(255, 255, 255, 0.65);
  margin: auto;
  text-align: center;
  border: 2px solid grey;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  margin: 10px 2px 0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.logout-mobile:hover {
  color: rgba(255, 255, 255, 1);
  border: 2px solid white;
}

.form-setting .ant-form-item-label {
  /* margin: auto 0; */
}

.form-setting .ant-form-item-has-error .ant-form-item-label {
  /* padding-bottom: 20px !important; */
}

.setting-table .ant-table-cell {
  padding: .5rem 1rem !important;
}

.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #699BF7 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #699BF7 !important;
  border-color: #699BF7 !important;
}

.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: #3f78e0 !important;
  border-color: #3f78e0 !important;
}

.ant-picker {
  padding: 10px 12px !important;
}

.ant-pagination {
  display: block !important;
}

.ant-pagination-options {
  display: none !important;
}

@media only screen and (max-width: 1200px) {
  .custom-textarea {
    max-width: 100% !important;
  }

  .textarea-count {
    right: 6% !important;
  }
}

@media only screen and (max-width: 900px) {
  .bt-main {
    width: 100% !important;
  }

  .ant-drawer-content-wrapper .ant-drawer-header {
    background: rgb(59, 69, 70) !important;

  }

  .ant-drawer-content-wrapper .ant-drawer-body {
    background: rgb(59, 69, 70) !important;
  }

  .public-form {
    padding: 40px 8px;
    width: 84%;
  }

  .circle {
    width: 20px;
    height: 20px;
  }

  .serial-container span:nth-child(2) {
    font-size: 10px;
  }

  .card-main {
    padding: 22px 12px;
  }

  .card-container {
    padding: 0px 0px !important;
  }

  .serial-container {
    min-width: 70px;
  }

  .btn-group-stop {
    flex-direction: column;
  }

  .done-table {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .input-upload {
    width: calc(100% - 65px);
  }

  .custom-col .ant-col-sm-flex {
    width: 100%;
  }

  .modal-label {
    flex-basis: 150px;
  }

  .setting-other-container {
    margin: 0 0 30px;
  }

  .header-ui .mobild-ui {
    display: flex;
    height: 100%;
  }

  .header-ui {
    margin-bottom: auto;
    margin-top: auto;
  }

  .bamberger-ui {
    margin-bottom: auto;
    margin-top: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  .app-ui-left {
    width: 100%;
  }

  .app-ui-right {
    display: none;
  }

  .site-layout {
    margin-left: 0px;
  }

  .ant-drawer {
    display: unset !important;
  }

  .account-logout {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .doc-set {
    margin: 0 0 20px;
  }

  .form-setting .ant-form-item-has-error .ant-form-item-label {
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 532px) {
  .phone-box {
    display: block;
  }

  .phone-box .ant-form-item:nth-child(1) {
    width: 100%;
  }

  .phone-box .ant-form-item:nth-child(2) {
    width: 100%;
  }

  .list-done-detail {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-insert {
    flex-wrap: wrap;
    gap: 10px;
  }

  .rma-detail-container {
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0;
  }

  .ant-image-preview-operations-operation {
    padding: 10px !important;
  }
}

@media only screen and (max-width: 450px) {
  .public-form {
    padding: 40px 8px;
    width: 90%;
  }

  .card-container,
  .card {
    padding: 15px;
  }

  .ant-picker-dropdown {
    left: 0 !important;
  }

  .ant-picker-dropdown .ant-picker-datetime-panel {
    flex-direction: column;
  }

  .search-clear {
    flex-wrap: wrap;
  }

  .megaforce-form-list .ant-form-item-control-input-content {
    min-width: 200px;
    width: 200px;
  }

  .search-tds {
    min-width: 200px;
    width: 100% !important;
    max-width: 300px;
  }

  .search-clear-rma {
    gap: 0;
  }

  .search-clear-rma {
    flex-wrap: wrap;
    gap: 20px;
  }

  main.ant-layout-content {
    margin: 24px 5px !important;
  }
}