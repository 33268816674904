.float-label {
  position: relative;
}

.label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 11px;
  transition: 0.2s ease all;
}

.as-placeholder {
  color: #D4D9DF;
}

.as-label {
  top: -8px;
  font-size: 12px !important;
  background: white;
  padding: 0 4px;
  margin-left: -4px;
  color: #454D58;
  z-index: 1000;
}

.label.as-placeholder{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inherit;
  width: 90%;
  margin-left: 25px;
}
/* //#D4D9DF; */


.ant-form-item-has-error .as-label {
  color: #D1333D;
}

.as-label.disabled{
      background: rgba(245,242,248,0.5);
      border-radius: 6px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
  /* z-index: auto !important; */
}
